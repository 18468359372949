import { SectionTitleProps } from "../../interfaces";

const SectionTitle:React.FC<SectionTitleProps> = ({ title, className }) => {

    return (
        <h2 className={`text-[1.95rem] tracking-tigth leading-[2.40rem] sm:text-4xl sm:text-center mb-4 sm:mb-6 ${className}`}>
            {title}
        </h2>
    )
}

export default SectionTitle;