import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import DefaultLanguageInterface from '../../../interfaces';
import ResponsiveImage from '../../../utils/ResponsiveImage/ResponsiveImage';
import SkeletonLoader from '../../../utils/SkeletonLoader/SkeletonLoader';
import SectionTitle from '../../../utils/SectionTitle/SectionTitle';
import SectionWrapper from '../../../utils/SectionWrapper/SectionWrapper';

// Import regular images
import t2 from '../../../images/screens/driver/gr2c.png';
import t3 from '../../../images/screens/driver/gr3c.png';
import t4 from '../../../images/screens/driver/gr4c.png';
import t6 from '../../../images/screens/driver/gr6c.png';
import t7 from '../../../images/screens/driver/gr7c.png';

// Import webp images
import w2 from '../../../images/screens/driver/Final/webp/gr2c.webp';
import w3 from '../../../images/screens/driver/Final/webp/gr3c.webp';
import w4 from '../../../images/screens/driver/Final/webp/gr4c.webp';
import w5 from '../../../images/screens/driver/Final/webp/gr5.webp';
import w6 from '../../../images/screens/driver/Final/webp/gr6c.webp';
import w7 from '../../../images/screens/driver/Final/webp/gr7c.webp';

import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';

const DriverCarousel: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            align: 'start',
            slidesToScroll: 1,
            containScroll: false,
            dragFree: false,
            loop: true
        },
        [
            Autoplay({
                delay: 3500,
                stopOnInteraction: false,
                playOnInit: true
            })
        ]
    );
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [visibleSlides, setVisibleSlides] = useState(4);
    const [isTextAnimating, setIsTextAnimating] = useState(false);

    const screens = [
        {
            images: {
                mobile: { webp: w3, fallback: t3 },
                tablet: { webp: w3, fallback: t3 },
                desktop: { webp: w3, fallback: t3 }
            },
            alt: languageData.driver.driver_how_it_works_1_title
        },
        {
            images: {
                mobile: { webp: w2, fallback: t2 },
                tablet: { webp: w2, fallback: t2 },
                desktop: { webp: w2, fallback: t2 }
            },
            alt: languageData.driver.driver_how_it_works_2_title
        },
        {
            images: {
                mobile: { webp: w5, fallback: w5 },
                tablet: { webp: w5, fallback: w5 },
                desktop: { webp: w5, fallback: w5 }
            },
            alt: languageData.driver.driver_how_it_works_3_title
        },

        {
            images: {
                mobile: { webp: w4, fallback: t4 },
                tablet: { webp: w4, fallback: t4 },
                desktop: { webp: w4, fallback: t4 }
            },
            alt: languageData.driver.driver_how_it_works_4_title
        },

        {
            images: {
                mobile: { webp: w6, fallback: t6 },
                tablet: { webp: w6, fallback: t6 },
                desktop: { webp: w6, fallback: t6 }
            },
            alt: languageData.driver.driver_how_it_works_5_title
        },

        {
            images: {
                mobile: { webp: w7, fallback: t7 },
                tablet: { webp: w7, fallback: t7 },
                desktop: { webp: w7, fallback: t7 }
            },
            alt: languageData.driver.driver_how_it_works_6_title
        },

    ];

    const totalDots = screens.length;

    const onSelect = useCallback((api: any) => {
        setSelectedIndex(api.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        const onSelect = () => {
            setIsTextAnimating(true);
            setTimeout(() => {
                setSelectedIndex(emblaApi.selectedScrollSnap());
                setIsTextAnimating(false);
            }, 300); // Match this with animation duration
        };

        emblaApi.on('select', onSelect);

        const handleResize = () => {
            if (window.innerWidth < 640) {
                setVisibleSlides(1);
            } else if (window.innerWidth < 768) {
                setVisibleSlides(2);
            } else if (window.innerWidth < 1024) {
                setVisibleSlides(3);
            } else {
                setVisibleSlides(4);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            emblaApi.off('select', onSelect);
            window.removeEventListener('resize', handleResize);
        };
    }, [emblaApi]);

    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    return (
        <SectionWrapper>
            <div className='flex flex-col gap-4'>
                <SectionTitle title={languageData.driver.driver_how_it_works_main_title} className='!mb-0' />
                <div className="relative h-8 mb-2 sm:mb-0 ">
                    <div className="absolute w-full">
                        <p 
                            className={`
                                w-full text-neutral-700 text-sm sm:text-base sm:text-center  max-w-3xl mx-auto
                                transition-opacity duration-300 ease-in-out
                                ${isTextAnimating ? 'opacity-0' : 'opacity-100'}
                            `}
                        >
                            {screens[selectedIndex].alt}
                        </p>
                    </div>
                </div>

                <div className="w-full max-w-[1000px] mx-auto">
                    <div className="overflow-hidden" ref={emblaRef}>
                        <div className="flex">
                            {screens.map((screen, index) => (
                                <div key={index} className={`flex-[0_0_100%] sm:flex-[0_0_50%] md:flex-[0_0_33.333%] lg:flex-[0_0_25%] min-w-0 select-none sm:mt-8`}>
                                    <div className="relative h-[390px] w-full flex items-center justify-center">
                                        <div className='absolute h-full w-full flex items-center justify-center'>
                                            <div className='shadow-[0px_0px_20px_rgba(0,0,0,0.5)] rounded-3xl'>
                                                <div className='bg-neutral-400 h-[380px] rounded-3xl w-[180px] z-0'>
                                                </div>
                                            </div>
                                        </div>
                                        <ResponsiveImage
                                            images={screen.images}
                                            alt={screen.alt}
                                            className="z-10 object-contain h-full h-[390px] sm:h-[390px]"
                                            innerClassName='object-contain h-full h-[390px] sm:h-[390px]'
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-2">
                    {Array.from({ length: screens.length }).map((_, index) => (
                        <button
                            key={index}
                            className={`w-[8px] h-[8px] rounded-full mx-1 ${index === selectedIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </div>
            </div>
        </SectionWrapper>
    );
};

export default DriverCarousel;