import React, { useEffect, useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ACTIVE_URL } from '../../constants';

export const AppRedirectLink = (activeLanguage: string, user: 'passenger' | 'driver', from: string): string => {
    const userAgent = navigator.userAgent;

    const googlePlay = `https://play.google.com/store/apps/details?id=com.taxigr.customer`;
    const appStore = `https://apps.apple.com/gr/app/taxi-gr-the-new-taxi-app/id6449004480`;

    const googlePlayDriver = `https://play.google.com/store/apps/details?id=com.taxigr.driver`;
    const appStoreDriver = `https://apps.apple.com/us/app/taxi-gr-driver/id6449005329`;

    if (/iPhone/i.test(userAgent)) {
        if (user === 'passenger') {
            return appStore;
        } else {
            return appStoreDriver;
        }
    } else if (/Android/i.test(userAgent)) {
        if (user === 'passenger') {
            return googlePlay;
        } else {
            return googlePlayDriver;
        }
    }

    return '';
};

export const postRedirect = async (app: 'passenger' | 'driver' = 'passenger', user_agent = navigator.userAgent, redirect_from: string) => {
    const requestData = {
        app: app,
        user_agent: user_agent,
        redirect_from: redirect_from,
    };

    try {
        const response = await fetch(`${ACTIVE_URL}/api/post_redirect_action/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data);
        } else {
            console.error('Failed to fetch responses');
        }
    } catch (error) {
        console.error('Error fetching responses:', error);
    }
};

export const RedirectComponent: React.FC<{ activeLanguage: string, from: string }> = ({ activeLanguage, from }) => {
    const { user } = useParams();
    const redirected = useRef(false);

    useEffect(() => {
        const handleRedirect = async () => {
            if (redirected.current) {
                return;
            }

            if (user === 'passenger' || user === 'driver') {
                redirected.current = true;
                await postRedirect(user, navigator.userAgent, from);
                const redirectUrl = AppRedirectLink(activeLanguage, user, from);
                window.location.href = redirectUrl;
            }
        };

        handleRedirect();
    }, [activeLanguage, from, user]);

    let to = '/';
    if (activeLanguage === 'en') {
        to = '/en/';
    }
    return <Navigate to={to} replace />;
};
