import React, { useState } from "react";
import { IonIcon } from "@ionic/react";

interface AdminAddCandidateProps {
    onClose: () => void;
    onAddCandidate: (name: string, email: string, phone: string) => void;
}

const AdminAddCandidate: React.FC<AdminAddCandidateProps> = ({ onClose, onAddCandidate }) => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const nameRegex = /^[a-zA-Z\s]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Validate the input values
    const isNameValid = nameRegex.test(name);
    const isEmailValid = emailRegex.test(email);
    const isPhoneValid = phoneRegex.test(phone);

    const isFormValid = isNameValid && isEmailValid && isPhoneValid;

    React.useEffect(() => {
        setName("");
        setEmail("");
        setPhone("");
      }, [onClose]);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (isFormValid) {
            onAddCandidate(name, email, phone);
            onClose();
        }
    };

    return (
        <div className="px-6 py-4">
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <div className='search-row relative  select-none'>
                        <IonIcon icon='person' className='font-semibold absolute top-3 left-3 ion-icon-item' style={{ transform: 'translateY:(-3px)' }} />
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                            className={`w-full p-2 pl-9 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`}
                            placeholder='First and last name e.g. Christos Papadopoulos'
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <div className='search-row relative  select-none'>
                        <IonIcon icon='mail' className='font-semibold absolute top-3 left-3 ion-icon-item' style={{ transform: 'translateY:(-2px)' }} />
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                            className={`w-full p-2 pl-9 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`}
                            placeholder='Email e.g. papadopoulos@gmail.com'
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="phone">Phone:</label>
                    <div className='search-row relative select-none'>
                        <IonIcon icon='call' className='font-semibold absolute top-3 left-3 ion-icon-item' style={{ transform: 'translateY:(-2px)' }} />
                        <input
                            type="tel"
                            id="phone"
                            value={phone}
                            onChange={handlePhoneChange}
                            className={`w-full p-2 pl-9 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`}
                            placeholder='Phone number e.g. 6934343434'
                        />
                    </div>
                </div>
                <div className="w-full flex items-center justify-between">
                    <button
                        type="submit"
                        className={`px-4 py-2 bg-blue-600 text-white cursor-pointer mt-1 text-m font-semibold rounded transition-colors shadow-md hover:bg-blue-700 focus:outline-none ${!isFormValid ? 'bg-gray-600 hover:bg-gray-700 cursor-not-allowed' : ''
                            }`}
                    >
                        &#43; Add Candidate
                    </button>
                    <button
                        type="button"
                        className="px-4 py-2 bg-red-500 text-white cursor-pointer mt-1 text-m font-semibold rounded transition-colors shadow-md hover:bg-red-600 focus:outline-none"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AdminAddCandidate;
