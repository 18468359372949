import React, { useState, ChangeEvent, useEffect } from 'react';
import { ACTIVE_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';

interface ImageUploaderProps {
    initialWidth?: number;
    initialBorderRadius?: number | null;
}

const AdminRoundImage: React.FC<ImageUploaderProps> = ({
    initialWidth = 300,
    initialBorderRadius = null,
}) => {
    console.log('AdminRoundImage rendering');

    const [image, setImage] = useState<string | null>(null);
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialWidth);
    const [borderRadius, setBorderRadius] = useState<string>(initialBorderRadius?.toString() ?? '');
    const [aspectRatio, setAspectRatio] = useState(1);
    const [title, setTitle] = useState<string>('');
    const MAX_WIDTH = 1200;
    const { authTokens } = useAuthContext();

    console.log('ACTIVE_URL:', ACTIVE_URL);

    useEffect(() => {
        console.log('authTokens:', authTokens);
    }, [authTokens]);

    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {

        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const newAspectRatio = img.width / img.height;
                    setAspectRatio(newAspectRatio);
                    const newWidth = Math.min(img.width, MAX_WIDTH);
                    setWidth(newWidth);
                    setHeight(newWidth / newAspectRatio);

                };
                img.src = event.target?.result as string;
                setImage(event.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        setHeight(width / aspectRatio);
    }, [width, aspectRatio]);

    const handleWidthChange = (newWidth: number) => {
        const constrainedWidth = Math.min(newWidth, MAX_WIDTH);
        setWidth(constrainedWidth);
        setHeight(constrainedWidth / aspectRatio);
    };

    const handleBorderRadiusChange = (value: string) => {
        const regex = /^\d*\.?\d*$/;
        if (regex.test(value) || value === '') {
            setBorderRadius(value);
        }
    };

    const handleSaveImage = async () => {
        if (!image || !title || !authTokens || !authTokens.access) return;
    
        try {
            console.log('Sending to backend:', {
                title,
                width,
                height,
                borderRadius: parseFloat(borderRadius)
            });
    
            const response = await fetch(`${ACTIVE_URL}/api/post_upload_image/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: JSON.stringify({
                    title,
                    image: image,  // This is the original image data URL
                    width,
                    height,
                    borderRadius: parseFloat(borderRadius),
                }),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Image saved successfully!', data);
            } else {
                console.error('Failed to save image');
            }
        } catch (error) {
            console.error('Error saving image:', error);
        }
    };

    const inputStyle = `px-2 py-1 mt-1 block w-full rounded-md border-neutral-500 shadow-sm 
    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
    [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`;
    const cornerRadiusPixels = Math.min(width, height) * (parseFloat(borderRadius) / 100);
    return (
        <div className="flex items-start space-x-8 p-4">
            <div className="flex flex-col space-y-4">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="mb-4"
                />
                {image && (
                    <>
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                Title
                            </label>
                            <input
                                type="text"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className={inputStyle}
                            />
                        </div>
                        <div>
                            <label htmlFor="width" className="block text-sm font-medium text-gray-700">
                                Width (px)
                            </label>
                            <input
                                type="number"
                                id="width"
                                value={width}
                                onChange={(e) => handleWidthChange(Number(e.target.value))}
                                max={MAX_WIDTH}
                                className={inputStyle}
                            />
                        </div>
                        <div>
                            <label htmlFor="height" className="block text-sm font-medium text-gray-700">
                                Height (px)
                            </label>
                            <input
                                type="number"
                                id="height"
                                value={Math.round(height)}
                                readOnly
                                className={`${inputStyle} bg-gray-100`}
                            />
                        </div>
                        <div>
                            <label htmlFor="borderRadius" className="block text-sm font-medium text-gray-700">
                                Border Radius (%)
                            </label>
                            <input
                                type="text"
                                id="borderRadius"
                                value={borderRadius}
                                onChange={(e) => handleBorderRadiusChange(e.target.value)}
                                className={inputStyle}
                            />
                        </div>
                        <button
                            onClick={() => {
                                console.log('Save Image button clicked');
                                try {
                                    handleSaveImage();
                                } catch (error) {
                                    console.error('Error in handleSaveImage:', error);
                                }
                            }}
                            className="mt-4 px-4 py-2 bg-main text-white rounded hover:bg-blue-700"
                        >
                            Save Image
                        </button>
                    </>
                )}
            </div>
            {image && (
                <div className="flex-shrink-0">
                    <img
                        src={image}
                        alt="Uploaded"
                        style={{
                            width: `${width}px`,
                            height: `${height}px`,
                            borderRadius: `${cornerRadiusPixels}px`,
                        }}
                        className="object-cover"
                    />
                </div>
            )}
        </div>
    );
};

export default AdminRoundImage;