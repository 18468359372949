import React from 'react';
import { IonIcon } from '@ionic/react';
import UndecitedThumbs from '../../../../../../utils/UndecitedThumbs/UndecitedThumbs';

interface CandidateApprovalRatingProps {
    score: number | null;
}

const CandidateApprovalRating: React.FC<CandidateApprovalRatingProps> = ({ score }) => {
    let bg_color = 'green-500';
    if (score !== null && (score < 50)) {
        bg_color = 'gray-700';
    }

    console.log(score);
    return (
        <div>

            <div>
                {(score !== null && score >= 75) ? (
                    <div>
                        <IonIcon icon="checkmark-done-outline" className={`font-semibold text-${bg_color}`} />
                        {score}%
                    </div>
                ) : (score !== null && score > 50 && score < 75) ? (
                    <div>
                        <IonIcon icon="checkmark-outline" className={`font-semibold text-${bg_color}`} />
                        {score}%
                    </div>
                ) : (score !== null && score >= 0 && score <= 50) ? (
                    <div>
                        <IonIcon icon="minus" className={`font-semibold text-${bg_color}`} />
                        {score}%
                    </div>
                ) : (
                    <div className='flex gap-2 text-gray-700'>
                        <UndecitedThumbs className='text-gray-700' />Evaluate
                    </div>
                )}


            </div>

        </div>
    )

}

export default CandidateApprovalRating;