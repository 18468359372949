import React from 'react';

interface ImageSet {
  mobile: {
    webp: string;
    fallback: string;
  };
  tablet?: {
    webp: string;
    fallback: string;
  };
  desktop: {
    webp: string;
    fallback: string;
  };
}

type BreakpointClasses = {
  mobile?: string;
  tablet?: string;
  desktop?: string;
  wide?: string;
};

interface ResponsiveImageProps {
  images: ImageSet;
  alt: string;
  isBackground?: boolean;
  className?: string | BreakpointClasses;
  innerClassName?: string;
  children?: React.ReactNode;
  backgroundPosition?: string;
  backgroundSize?: string;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  images,
  alt,
  isBackground = false,
  className = '',
  children,
  backgroundPosition = 'center',
  backgroundSize = 'cover',
  innerClassName = '',
}) => {
  // Handle className based on type
  const resolveClassName = () => {
    if (typeof className === 'string') {
      return className;
    }

    // If it's an object, combine the classes with breakpoint prefixes
    return `
      ${className.mobile || ''}
      ${className.tablet ? `md:${className.tablet}` : ''}
      ${className.desktop ? `lg:${className.desktop}` : ''}
      ${className.wide ? `xl:${className.wide}` : ''}
    `.trim();
  };

  const finalClassName = resolveClassName();

  if (isBackground) {
    return (
      <>
        <style>{`
          .bg-image {
            background-image: url(${images.mobile.fallback});
          }
          @supports (background-image: -webkit-image-set(url("") 1x)) {
            .bg-image {
              background-image: -webkit-image-set(
                url(${images.mobile.webp}) 1x
              );
            }
          }
          @media (min-width: 768px) {
            .bg-image {
              background-image: url(${images.desktop.fallback});
            }
            @supports (background-image: -webkit-image-set(url("") 1x)) {
              .bg-image {
                background-image: -webkit-image-set(
                  url(${images.desktop.webp}) 1x
                );
              }
            }
          }
        `}</style>
        <div
          className={`bg-image ${finalClassName}`}
          style={{
            backgroundPosition,
            backgroundSize,
            backgroundRepeat: 'no-repeat',
          }}
          role="img"
          aria-label={alt}
        >
          {children}
        </div>
      </>
    );
  }

  return (
    <picture className={finalClassName}>
      <source
        media="(min-width: 768px)"
        srcSet={images.desktop.webp}
        type="image/webp"
      />
      {/* <source
        media="(min-width: 768px)"
        srcSet={images.desktop.fallback}
        type="image/png"
      />
      <source
        srcSet={images.mobile.webp}
        type="image/webp"
      />
      <source
        srcSet={images.mobile.fallback}
        type="image/png"
      /> */}
      <img
        src={images.desktop.webp}
        alt={alt}
        loading="lazy"
        className={innerClassName ? innerClassName : "w-full h-auto"}
      />
    </picture>
  );
};

export default ResponsiveImage;