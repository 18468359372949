import React, { useEffect, useState, useRef } from 'react';
import SectionTitle from '../../../utils/SectionTitle/SectionTitle';
import SectionWrapper from '../../../utils/SectionWrapper/SectionWrapper';
import LazyHomeCarousel from './LazyHomeCarousel';

const CarouselWrapper: React.FC<{ activeLanguage: string }> = ({ activeLanguage }) => {
    const [isInView, setIsInView] = useState(false); // Track if the carousel is in view
    const carouselRef = useRef<HTMLDivElement | null>(null); // Reference for the carousel

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true); // Set to true when in view
                    observer.disconnect(); // Stop observing once in view
                }
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        if (carouselRef.current) {
            observer.observe(carouselRef.current);
        }

        return () => {
            if (carouselRef.current) {
                observer.unobserve(carouselRef.current);
            }
        };
    }, []);

    return (
        <SectionWrapper>
            <SectionTitle title="Γρήγορο, εύκολο και απόλυτα ασφαλές" />
            <p className="text-sm sm:text-base sm:text-center mb-8 sm:mb-12 max-w-3xl mx-auto hidden sm:block">
                Το taxi.gr είναι το app μετακίνησης που χρειάζεσαι, ακριβώς εκεί που το χρειάζεσαι.
            </p>
            <div ref={carouselRef}>
                {isInView && <LazyHomeCarousel activeLanguage={activeLanguage} />} {/* Render LazyHomeCarousel only when in view */}
            </div>
        </SectionWrapper>
    );
};

export default CarouselWrapper;