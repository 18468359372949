import React, { useEffect } from 'react';
import HeroSection from '../../Components/Home/HomeHero/HomeHero';
import HomeDriver from '../../Components/Home/HomeDriver/HomeDriver';
import HomeLoveTheRide from '../../Components/Home/HomeLoveTheRide/HomeLoveTheRide';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';
import HomeFleet from '../../Components/Home/HomeFleet/HomeFleet';
import HomeNews from '../../Components/Home/HomeNews/HomeNews';
import HomeCarousel from '../../Components/Home/HomeCarousel/CarouselWrapper';
import ToDriver from '../../Components/Home/ToDriver/ToDriver';

interface HomeProps {
    activeLanguage: string;
}

const Home: React.FC<HomeProps> = (props) => {
    const { activeLanguage } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <HeroSection activeLanguage={activeLanguage} />
            <HomeCarousel activeLanguage={activeLanguage} />
            <HomeFleet activeLanguage={activeLanguage} />
            <HomeLoveTheRide activeLanguage={activeLanguage} />
            <DownloadOurApp activeLanguage={activeLanguage} />
            <HomeNews activeLanguage={activeLanguage} />
        </div>
    );
};

export default Home;