import React, { useEffect, useState } from "react";
import { Trash2Icon } from "lucide-react";

import { Input } from "../../../@/components/ui/input";
import { Button } from "../../../@/components/ui/button";


interface DriverFormFileProps {
    sendFile: (file: File | null) => void;
    preSelectedFile?: File | null;
}

const DriverFormFile: React.FC<DriverFormFileProps> = ({ sendFile, preSelectedFile = null }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(preSelectedFile);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    useEffect(() => {
        setSelectedFile(preSelectedFile);
        if (preSelectedFile) {
            const url = URL.createObjectURL(preSelectedFile);
            setPreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [preSelectedFile]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setSelectedFile(selected);
                sendFile(selected);
                const url = URL.createObjectURL(selected);
                setPreviewUrl(url);
            } else {
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        sendFile(null);
    };

    return (
        <div className='space-y-4 '>
            {!selectedFile && (

                <Input
                    id="picture"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/jpeg, image/jpg, image/png, image/gif"
                    selectedFile={selectedFile}
                />


            )}
            {selectedFile && previewUrl && (
                <div className="flex justify-center items-center ">
                    <div className="relative inline-block">
                        <img
                            src={previewUrl}
                            alt="Preview"
                            className="rounded-lg max-h-[47vh] w-auto object-contain"
                        />
                        <Button
                            onClick={handleDelete}
                            className="absolute top-2 right-2 bg-red-600 hover:bg-red-500 text-white h-12 w-12 rounded-full p-0 flex items-center justify-center"
                        >
                            <Trash2Icon className='text-xl' />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DriverFormFile;