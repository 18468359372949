import React, { useState } from 'react';
import AdminActionHeader from '../AdminActionHeader/AdminActionHeader';
import { IonIcon } from '@ionic/react';
import CKEditorComponent from '../../../../utils/ckEditor/CKEditorComponent';
import AdminAcitonsVisibilitySelect from '../AdminAcitonsSelectGroup/AdminAcitonsSelectGroup';

interface AdminActionAddNoteProps {
    closeAction: () => void;
    setActionData: (actionData: noteDataProps) => void;
    className?: string;
}

interface noteDataProps {
    type?: 'note';
    note: string,
    ccGroup: string,
}

const AdminActionAddNote: React.FC<AdminActionAddNoteProps> = ({ className, closeAction, setActionData }) => {
    const initialNoteData: noteDataProps = {
        type: 'note',
        note: '',
        ccGroup: '',
    };
    const [noteData, setNoteData] = useState<noteDataProps>(initialNoteData);
    
    const setCcGroup = (groupVisibilityId: string) => {
        setNoteData({ ...noteData, ccGroup: groupVisibilityId })
        setActionData(noteData);
    }

    const handleNoteChange = (note: string) => {
        setNoteData({ ...noteData, note: note })
        setActionData(noteData);
    }

    return (
        <div className={`${className} border-gray-400 border rounded-md`}>
            <AdminActionHeader className="rounded-t-md" title='Add a Note' icon={<IonIcon icon='chatbox-ellipses' />} closeAction={() => closeAction()} />
            <CKEditorComponent className="" onChange={(value:string) => handleNoteChange(value)} />
            <AdminAcitonsVisibilitySelect label='Select Share Group' setGroup={setCcGroup} />
        </div>
    )
}

export default AdminActionAddNote;