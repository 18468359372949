import React from 'react';
import WhyUsSquare from './WhyUsSquare/WhyUsSquare';
import { Coins, Phone, Car, Users, Clock, Navigation, LucideIcon } from 'lucide-react';

interface WhyUsProps {
    activeLanguage: string;
}

interface WhyUsSquareItem {
    title_text_gr: string;
    title_text_en: string;
    content_text_gr: string;
    content_text_en: string;
    Icon: LucideIcon;
}

const WhyUs:React.FC<WhyUsProps> = ({activeLanguage}) => {
    const why_us_squares: WhyUsSquareItem[] = [
        {
            title_text_gr: 'Κέρδισε έως και 7€ ανά διαδρομή',
            title_text_en: 'Earn up to 7€ per ride',
            content_text_gr: 'Οδήγησε καθημερινά μαζί μας και κέρδισε επιπλέον χρήματα για κάθε σου διαδρομή.',
            content_text_en: 'As long as the customer chooses the category your vehicle belongs to.',
            Icon: Coins,
        },
        {
            title_text_gr: '24/7 Υποστήριξη',
            title_text_en: '24/7 Support',
            content_text_gr: 'Είμαστε 100% ελληνική εταιρία που βρίσκεται πάντα δίπλα σας με υποστήριξη 24/7.',
            content_text_en: 'Invaluable peace of mind when you need it most.',
            Icon: Phone,
        },
        {
            title_text_gr: 'Με μέγιστη ευελιξία',
            title_text_en: 'Maximum Flexibility',
            content_text_gr: 'Εργάσου ελεύθερα όποτε το επιθυμείς, αυξάνοντας τα ημερήσια έσοδά σου.',
            content_text_en: 'No mandatory branding, no scheduling commitments. Work and make money on your own terms.',
            Icon: Car,
        },
        {
            title_text_gr: 'Χωρίς αποκλειστικότητες',
            title_text_en: 'Non-exclusive',
            content_text_gr: 'Δεν σε δεσμεύουμε με αποκλειστικότητα και εργαζόμαστε για την προστασία των συμφερόντων σου.',
            content_text_en: 'You are respected and understood. We do not tie you down to exclusivity and work to protect your interests.',
            Icon: Users,
        },
        {
            title_text_gr: 'Άμεση ζήτηση και ραντεβού',
            title_text_en: 'On demand and scheduled rides',
            content_text_gr: 'Επωφελήσου από τις άμεσες κρατήσεις και τα ραντεβού, παρέχοντας παράλληλα στους επιβάτες σου ηρεμία και ασφάλεια.',
            content_text_en: 'Apart from ASAP bookings, benefit from pre-bookings whilst giving your clients peace of mind when they need it most.',
            Icon: Clock,
        },
        {
            title_text_gr: 'Αναλυτικές οδηγίες πλοήγησης',
            title_text_en: 'Let the app lead you',
            content_text_gr: 'Συνδεθείτε, αρχίστε να δέχεστε διαδρομές και λάβετε αναλυτικές οδηγίες πλοήγησης με την εκπληκτική εφαρμογή οδηγού.',
            content_text_en: 'Log-in, start accepting trips and get turn-by-turn directions with our awesome driver\'s app.',
            Icon: Navigation,
        },
    ]

    return (
        <div className='pt-4 '>
            <div className='grid gap-x-2 xmd:gap-x-4 gap-y-3 grid-cols-1 xmd:grid-cols-3 max-w-1128 m-auto'>
                {why_us_squares.map((why_us_square, index) => (
                    <WhyUsSquare 
                        key={index}
                        title={activeLanguage === 'el' ? why_us_square.title_text_gr : why_us_square.title_text_en}
                        content_text={activeLanguage === 'el' ? why_us_square.content_text_gr : why_us_square.content_text_en}
                        Icon={why_us_square.Icon}
                    />
                ))}
            </div>
        </div>
    )
}

export default WhyUs;
