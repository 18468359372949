import React, { useState } from 'react';
import './AdminWrapper.css';
import AdminSideBar from '../AdminSideBar/AdminSideBar';
import AdminHome from '../AdminHome/AdminHome';
import AdminFormAnswers from '../AdminFormAnswers/AdminFormAnswers';
import AdminFormQuestions from '../AdminFormQuestions/AdminFormQuestions';
import AdminFormQuestionnaire from '../AdminFormQuestionnaire/AdminFormQuestionnaire';
import AdminCandidates from '../AdminCandidates/AdminCandidates';
import AdminLogs from '../AdminLogs/AdminLogs';
import AdminSettings from '../AdminSettings/AdminSettings';
import AdminUsers from '../AdminUsers/AdminUsers';
import AdminLegal from '../AdminLegal/AdminLegal';
import AdminNews from '../AdminNews/AdminNews';
import Microapps from '../AdminMicroapps/AdminMicroapps';

const AdminWrapper: React.FC = () => {
    const [activeScreen, setActiveScreen] = useState<string>('CandidatesMain');

    const setActiveScreenHandler = (screen: string) => {
        setActiveScreen(screen);
    };

    return (
        <div className='admin-wrapper'>
            <AdminSideBar activeScreen={activeScreen}  setActiveScreen={setActiveScreenHandler}  />
            <div className='admin-wrapper-content-wrapper'>
                {activeScreen === 'dashboard' && <AdminHome />}
                {activeScreen === 'Results' && <AdminFormAnswers />}
                {activeScreen === 'Questions' && <AdminFormQuestions />}
                {activeScreen === 'Questionnaire' && <AdminFormQuestionnaire />}
                {activeScreen === 'CandidatesMain' && <AdminCandidates />}
                {activeScreen === 'logs' && <AdminLogs />}
                {activeScreen === 'settings' && <AdminSettings />}
                {activeScreen === 'users' && <AdminUsers />}
                {activeScreen === 'WebsectionSettings' && <AdminLegal />}
                {activeScreen === 'News' && <AdminNews />}
                {activeScreen === 'Microapps' && <Microapps />}
            </div>
        </div>
    )
};

export default AdminWrapper;