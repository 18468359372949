import React from 'react';
import './ContactHeader.css';


const ContactHeader: React.FC = () => {

    return (
        <div className="contact_header-container ">
            <div className="home_header-text animate-fadeUp">
                <div className='home_header-text--title animate-fadeUp'>Σε ακούμε</div>
                <div className='home_header-text--text delay-75 animate-fadeUp'>
                    Όλη την ημέρα, κάθε μέρα.
                    <br></br><br></br>
                    Μη διστάσετε να επικοινωνήσετε μαζί μας μέσω της παρακάτω φόρμας. Μπορεί επίσης να απαντηθούν οι ερωτήσεις σας πιο γρήγορα στην ενότητα <a href='/passenger#passenger_faq-container' className='underline'>Συχνές Ερωτήσεις</a>.
                </div>
            </div>
        </div>
    )

}

export default ContactHeader;