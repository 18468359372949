import React, { useEffect, useState, useRef } from 'react';
import DriverHeader from '../../Components/Driver/DriverHeader/DriverHeader';
import DefaultLanguageInterface from '../../interfaces';
import DriverReasons from '../../Components/Driver/DriverReasons/DriverReasons';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';
import DriverCarousel from '../../Components/Driver/DriverCarousel/DriverCarousel';

const Contact: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
  const [isInView, setIsInView] = useState(false);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, []);

  return (
    <div>
      <DriverHeader activeLanguage={activeLanguage} />
      <DriverReasons activeLanguage={activeLanguage} />
      <div ref={carouselRef}>
        {isInView && <DriverCarousel activeLanguage={activeLanguage} />} {/* Render only when in view */}
      </div>
      {/* <ToPassenger activeLanguage={activeLanguage} /> */}
      {/* <DriverHowItWorks activeLanguage={activeLanguage} /> */}
      {/* <HomeDriver activeLanguage={activeLanguage} buttonText='Κάνε εγγραφή' buttonClickLink={`${activeLanguage == 'en' ? '/en' : ''}/drivers-form`}/> */}
      <DownloadOurApp activeLanguage={activeLanguage} selectedMode={'driver'} />
    </div>
  );
};

export default Contact;