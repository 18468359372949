import React, { useEffect, useState } from 'react';
import { useQuery } from "@tanstack/react-query";

import { DefaultLanguageInterface, TermsAndConditionsDescription } from '../../../../interfaces';
import HTMLRenderer from '../../../../utils/HTMLRenderer';
import { fetchTermsAndConditions } from '../../../../queries';

const TermsAndConditionsContent:React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [TermsAndConditionsContentGr, setTermsAndConditionsContentGr] = useState<string>('');
    const [TermsAndConditionsContentEn, setTermsAndConditionsContentEn] = useState<string>('');
    const { data: termsAndConditionsContent, error } = useQuery<TermsAndConditionsDescription, Error>({
        queryKey: ["termsAndConditions"],
        queryFn: fetchTermsAndConditions,
        staleTime: 5 * 60 * 1000
    });

    if (error) {
        console.error('Error fetching terms and conditions:', error);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (termsAndConditionsContent) {
            setTermsAndConditionsContentGr(termsAndConditionsContent.description_gr);
            setTermsAndConditionsContentEn(termsAndConditionsContent.description_en);
        }   
    }, [termsAndConditionsContent]);

    return (
        <div className='max-w-1128 m-auto pt-12 pb-10 px-4  1180:px-0 xmd:pt-20 xmd:pb-36 '>
            <HTMLRenderer htmlContent={activeLanguage === 'el' ? TermsAndConditionsContentGr : TermsAndConditionsContentEn} />
        </div>
    )
}

export default TermsAndConditionsContent;