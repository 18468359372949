import React, { useState } from 'react';
import { ChevronRightIcon } from 'lucide-react';

import SkeletonLoader from '../../../utils/SkeletonLoader/SkeletonLoader';

interface ArticleProps {
    activeLanguage: string;
    imageUrl: string;
    title: string;
    publisher: string;
    previewText: string;
    articleUrl: string;
    loading: boolean;
}

const PressItem: React.FC<ArticleProps> = ({ imageUrl, title, publisher, previewText, articleUrl, loading }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '12rem',
    };
    
    return (
        <a href={articleUrl} target="_blank" rel="noopener noreferrer" className="flex flex-col gap-2" onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {loading ? <SkeletonLoader height={'208px'} width={'100%'} borderRadius={6} /> : <div style={backgroundImageStyle} className="rounded-md"></div>}
            <div className="flex flex-row gap-3">
                <div className="w-4/5">
                    {loading ? 
                        <SkeletonLoader height={'20px'} width={'100%'} borderRadius={6} /> : 
                        <h4 className="text-lg text-neutral-700 leading-[1.4rem]">{title}</h4>
                    }

                    {loading ? 
                        <SkeletonLoader height={'10px'} width={'30%'} borderRadius={6} /> : 
                        <p className="text-xs text-neutral-600 mt-0.5">{publisher}</p>
                    }
                </div>
                <div className="w-1/5 flex items-center justify-center">
                    {loading ? <SkeletonLoader isCircle={true} height={'35px'} width={'35px'} /> : <ChevronRightIcon className={`${isHovered ? 'ml-3 text-main' : 'text-neutral-600'} text-xl duration-200`} />}
                </div>
            </div>
        </a>
    )
};

export default PressItem;