import React, { useEffect, useRef, useState } from 'react';
import { XIcon } from 'lucide-react';
import { RemoveScroll } from 'react-remove-scroll';
import './QrPopup.css';
import HTMLRenderer from '../../../../utils/HTMLRenderer';
import { AppRedirectLink } from '../../../../utils/AppRedirectLink/AppRedirectLink';
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';

interface QrPopupProps {
    header_text: string;
    body_text: string;
    onClose: () => void;
    forProp?: 'passenger' | 'driver';
    activeLanguage: string;
}

const QrPopup: React.FC<QrPopupProps> = ({ onClose, header_text, body_text, forProp = 'passenger', activeLanguage }) => {
    const [isMobile, setIsMobile] = useState(true);

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const redirected = useRef(false);

    const handleClose = () => {
        onClose();
    };

    const handlePopupClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (redirected.current) {
            return;
        }
        const userAgent = navigator.userAgent;
        const isMobile = /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent);

        if (isMobile) {
            const newTab = window.open(AppRedirectLink(activeLanguage, forProp, 'website'), '_blank');
            if (newTab) {
                setTimeout(() => newTab.close(), 5000);
            } else {
                console.error('Failed to open a new tab.');
            }
        }
        if (!isMobile) {
            setIsMobile(false);
        }
        redirected.current = true;
    }, []);

    if (isMobile) {
        return null;
    }

    return (
        <RemoveScroll>
            <div className="fixed top-0 left-0 w-full h-full animate-fadeIn  bg-black bg-opacity-40 flex items-center justify-center z-10" onClick={handleClose}>
                <div className='bg-main flex flex-col py-12 px-4 text-primary items-center justify-center rounded-3xl sm:min-h-[415px] sm:w-[400px] h-auto relative'>
                    <span className="absolute top-4 right-4 text-xl text-white flex  items-center py-3 px-3 bg-main hover:bg-blue-600 duration-100 rounded-full cursor-pointer z-10" onClick={handleClose}>
                        <XIcon />
                    </span>
                    <div className="mt-2 text-3xl font-normal mb-0 text-center text-white ">
                        <HTMLRenderer htmlContent={header_text} className='leading-9' />
                    </div>
                    <div className={`h-[240px] w-[240px] my-4 rounded-sm shadow-lg ${forProp === 'passenger' ? 'qr_wrap_passenger' : 'qr_wrap_driver'}`}></div>
                    <div className='max-w-[340px] text-center text-neutral-200'>
                        {forProp === 'passenger' ? languageData.home.home_popup_text : languageData.home.home_popup_text_driver}
                    </div>
                </div>
            </div>
        </RemoveScroll>
    );
};


export default QrPopup;
