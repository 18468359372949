import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import LogoSvg from '../../images/LogoSvg';
import driverImageMobile from '../../images/driver/driver_page_bg_mobile.jpg';

import DriverFormProgressBar from './DriverFormProgressBar/DriverFormProgressBar';
import { ACTIVE_URL } from '../../constants';
import { DriverInfo, driverInfoMetadataItems } from './DriverFormMetadata';
import { isValidEmail, isValidPhoneNumber } from '../../utils/InputField/InputFieldValidation';
import DriverFormRenderQuestion from './DriverFormRenderQuestion/DriverFormRenderQuestion';
import useTrackEvent from '../../utils/UseTrackEvent/useTrackEvent';

interface DriverFormNewProps {
    activeLanguage: string;
    email: string;
    phone_number: string;
    city: string;
}

const DriverFormNew: React.FC<DriverFormNewProps> = ({ activeLanguage, email, phone_number, city }) => {
    const [advanceAttempt, setAdvanceAttempt] = useState(false);
    const [isTheLastQuestion, setIsTheLastQuestion] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const formSubmitted = useRef(false);
    const trackEvent = useTrackEvent();

    const [driverInfo, setDriverInfo] = useState<DriverInfo>({

        email: email,
        phone_number: phone_number,
        city: city,
        first_name: '',
        last_name: '',

        receipt: null,
        edx_certificate: null,
        vehicle_registration: null,
        id_front: null,
        id_back: null,
        driver_license_front: null,
        driver_license_back: null,
        profile_image: null,
        vehicle_image: null,
        bank_receipt: null,
    });

    const [currentQuestionKey, setCurrentQuestionKey] = useState<keyof DriverInfo | null>(null);
    const [questionHistory, setQuestionHistory] = useState<(keyof DriverInfo)[]>([]);
    const [currentPosition, setCurrentPosition] = useState<number>(-1);
    const [prefilledStart, setPrefilledStart] = useState(false);

    useEffect(() => {
        if (questionHistory === null || questionHistory.length === 0) {
            const newHistory: (keyof DriverInfo)[] = [];
            const fieldsToCheck = [
                { key: 'email', isValid: isValidEmail(driverInfo.email) },
                { key: 'phone_number', isValid: isValidPhoneNumber(driverInfo.phone_number) },
                { key: 'city', isValid: !!driverInfo.city }
            ];

            fieldsToCheck.sort((a, b) =>
                driverInfoMetadataItems[a.key as keyof DriverInfo].position -
                driverInfoMetadataItems[b.key as keyof DriverInfo].position
            );

            for (const field of fieldsToCheck) {
                if (field.isValid) {
                    newHistory.push(field.key as keyof DriverInfo);
                } else {
                    break;  // Stop at the first invalid field
                }
            }

            setQuestionHistory(newHistory);
            if (newHistory.length > 0) {
                setPrefilledStart(true);
            }
        }
    }, []);

    useEffect(() => {
        if (prefilledStart) {
            const nextQuestion = getNextQuestion();
            if (nextQuestion) {
                setAdvanceAttempt(true);
            }
        }

    }, [prefilledStart]);

    const getCurrentQuestionStatus = () => {
        const totalQuestions = Object.keys(driverInfoMetadataItems).length;
        const latestPosition = currentQuestionKey ? driverInfoMetadataItems[currentQuestionKey].position + 1 : 1;
        return { latestPosition, totalQuestions };
    };
    const { latestPosition, totalQuestions } = getCurrentQuestionStatus();
    useEffect(() => {
        setIsTheLastQuestion(currentPosition === totalQuestions - 1)
    }, [currentQuestionKey]);
    // Store the driver info in local storage
    const handleInputChange = (key: keyof DriverInfo, value: any) => {
        setDriverInfo(prev => ({ ...prev, [key]: value }));
    };

    // Check if the next button should be disabled
    const isNextButtonDisabled = () => {

        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'file' && !driverInfo[currentQuestionKey]) {
            return true;
        }
        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'email' && driverInfo[currentQuestionKey] === '') {
            return true;
        }
        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'phone' && driverInfo[currentQuestionKey] === '') {
            return true;
        }
        if (currentQuestionKey) {
            const value = driverInfo[currentQuestionKey];
            if (typeof value === 'string' && value.length < 3) {
                return true;
            }
        }
        return false;
    };


    const getNextQuestion = (): keyof DriverInfo | null => {
        // Ensure all keys in questionHistory are valid and exclude null explicitly.
        const validAnsweredQuestionsKeys = questionHistory.filter((key): key is keyof DriverInfo => key !== null);

        // Calculate the currentPosition based on positions of answered questions.
        const currentPosition = validAnsweredQuestionsKeys.length > 0
            ? Math.max(...validAnsweredQuestionsKeys.map(key => driverInfoMetadataItems[key].position), -1)
            : -1;

        // Find the next question based on the currentPosition.
        const nextQuestionEntry = Object.entries(driverInfoMetadataItems)
            .filter(([key, _]) => !validAnsweredQuestionsKeys.includes(key as keyof DriverInfo))
            .sort((a, b) => a[1].position - b[1].position)
            .find(([_, metadata]) => metadata.position > currentPosition);

        return nextQuestionEntry ? nextQuestionEntry[0] as keyof DriverInfo : null;
    };

    const updateDriverInfoAndAdvance = (key: keyof DriverInfo, newValue: any) => {
        setDriverInfo(prev => ({ ...prev, [key]: newValue }));

        if (!questionHistory.includes(key)) {
            setQuestionHistory(prev => [...prev, key]);
        }
        // Advance to the next question
        setAdvanceAttempt(true);
    };

    const skipQuestion = () => {
        const nextQuestion = getNextQuestion();
        if (nextQuestion) {
            setQuestionHistory(prev => {
                const newHistory = [...prev, nextQuestion];
                return newHistory;
            });
            setAdvanceAttempt(true)
        } else {
            console.log("No more questions to skip to.");
        }
    };

    useEffect(() => {
        if (advanceAttempt || questionHistory.length === 0) {
            const nextQuestion = getNextQuestion();
            setAdvanceAttempt(false);
            if (nextQuestion) {
                setCurrentQuestionKey(nextQuestion);
                setCurrentPosition(driverInfoMetadataItems[nextQuestion].position);
            } else {
                console.log("All questions have been addressed.");
            }
        }
    }, [advanceAttempt, questionHistory.length]);

    const handleGoBack = () => {
        if (currentPosition > 0) {
            setQuestionHistory(prevHistory => {
                const newHistory = [...prevHistory];
                newHistory.pop();

                const newPosition = prevHistory.length - 1;
                setCurrentPosition(newPosition);

                const previousQuestionKey = prevHistory[newPosition];
                if (previousQuestionKey) {
                    setCurrentQuestionKey(previousQuestionKey);
                }

                return newHistory;
            });
        }
    };

    const submitDriverForm = async () => {
        if (formSubmitted.current) {
            return;
        }
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('first_name', driverInfo.first_name);
            formData.append('last_name', driverInfo.last_name);
            formData.append('email', driverInfo.email);
            formData.append('phone_number', driverInfo.phone_number);
            formData.append('city', driverInfo.city);
            formData.append('receipt', driverInfo.receipt as File);
            formData.append('edx_certificate', driverInfo.edx_certificate as File);
            formData.append('vehicle_registration', driverInfo.vehicle_registration as File);
            formData.append('id_front', driverInfo.id_front as File);
            formData.append('id_back', driverInfo.id_back as File);
            formData.append('driver_license_front', driverInfo.driver_license_front as File);
            formData.append('driver_license_back', driverInfo.driver_license_back as File);
            formData.append('profile_image', driverInfo.profile_image as File);
            formData.append('vehicle_image', driverInfo.vehicle_image as File);
            formData.append('bank_receipt', driverInfo.bank_receipt as File);


            const response = await fetch(`${ACTIVE_URL}/api/post_driver_form/`, {
                method: 'POST',
                body: formData,
            });
            formSubmitted.current = true;
            if (response.ok) {
                const data = await response.json();
                trackEvent('completeRegistrationForm', 'form', 'driver_page', {
                    city: city,
                    language: activeLanguage
                });
                setCompleted(true);
                setLoading(false);
            } else {
                console.error('Failed to post');
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error fetching responses:', error);
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    }

    return (

        <div className='h-screen pb-8'>
            <Link to='/' className='h-16 flex items-center justify-center w-screen px-2'>
                <LogoSvg fill='#002fff' pathStyle="duration-200 transition-property-fill" className="h-[1.95rem]" />
            </Link>
            <div className='px-2 max-w-1128 m-auto'>
                <div className='bg-opacity-95 rounded-md min-h-[90vh] flex items-center justify-center relative bg-cover '
                    style={{ backgroundImage: `url(${driverImageMobile})` }}>
                    <div className="absolute inset-0 bg-black opacity-70 rounded-md"></div>
                    {
                        !completed ? (
                            <div className="z-10 w-full flex justify-center pb-4">
                                <DriverFormProgressBar currentStep={latestPosition} totalSteps={totalQuestions} />
                                <form onSubmit={(e) => e.preventDefault()} className='flex flex-col h-full max-h-[80%] gap-5 sm:gap-4 w-full px-4 md:w-1/2 max-w-[500px] z-10 '>
                                    {currentQuestionKey && (
                                        <DriverFormRenderQuestion
                                            currentQuestionKey={currentQuestionKey}
                                            driverInfo={driverInfo}
                                            handleInputChange={handleInputChange}
                                        />
                                    )}
                                    <div className='w-full flex items-center justify-between'>
                                        {currentPosition > 0 && (
                                            <button className={`${loading ? 'bg-neutral-300 text-neutral-700' : 'bg-neutral-100 text-neutral-800 hover:bg-neutral-50 cursor-pointer'}  rounded text-base px-2 py-2 h-[36px]  duration-100 flex items-center justify-center gap-1`} disabled={loading} type="button" onClick={handleGoBack}>
                                                <ChevronLeftIcon className='mt-0.5 h-4' />
                                            </button>
                                        )}
                                        <div className='flex gap-2 ml-auto'>
                                            {/* {currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].skippable && (
                                                <button
                                                    className='bg-gray-400 text-neutral-100 rounded text-base px-4 py-2 duration-100 shadow-lg flex items-center justify-center gap-2'
                                                    type="button"
                                                    onClick={skipQuestion}
                                                >
                                                    Παράληψη
                                                </button>
                                            )} */}
                                            <button
                                                className={`${loading ? 'bg-secondary' : 'bg-secondary cursor-pointer'} text-white rounded text-base pl-4 pr-2 py-2 h-[36px]  duration-100 shadow-lg flex items-center justify-center gap-1 `}
                                                type="button"
                                                disabled={isNextButtonDisabled() || loading}
                                                onClick={() => {
                                                    if (currentQuestionKey) {
                                                        if (isTheLastQuestion) {
                                                            submitDriverForm();
                                                        } else {
                                                            updateDriverInfoAndAdvance(currentQuestionKey, driverInfo[currentQuestionKey]);
                                                        }
                                                    }
                                                }}>
                                                {loading ? (
                                                    <>
                                                        <div className='px-10 flex gap-4 text-white'>
                                                            <IonSpinner name="crescent" ></IonSpinner>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {isTheLastQuestion ? 'Υποβολή' : 'Επόμενο'}
                                                        <ChevronRightIcon className='mt-0.5 h-4' />
                                                    </>
                                                )}
                                            </button>

                                        </div>

                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className='text-white rounded-md min-h-[90vh] flex items-center gap-6  flex-col justify-center relative px-4 md:px-0'>
                                <h3 className='text-4xl '>
                                    Καλωσήρθατε!
                                </h3>
                                <p className='max-w-[500px] text-center'>
                                    Αγαπητέ/ή {driverInfo.first_name} {driverInfo.last_name}, η αίτησή σας ολοκληρώθηκε με επιτυχία!
                                    Σας έχει αποσταλεί email επιβεβαίωσης στο {driverInfo.email}.
                                </p>
                                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} className='bg-main px-6 py-2 text-white rounded-md'>
                                    Έξοδος
                                </Link>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default DriverFormNew;