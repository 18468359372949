import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { InstagramIcon, FacebookIcon, TwitterIcon, LinkedinIcon } from 'lucide-react';

import { ReactComponent as TiktokIcon } from '../../images/footer/logo_tiktok.svg'
import { ReactComponent as LogoSvg } from '../../images/logo.svg';
import QrPopup from '../Home/HomeHero/QrPopup/QrPopup';
import ActionButton from '../ActionButton/ActionButton';

import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

interface FooterProps {
    activeLanguage: string;
}

const Footer: React.FC<FooterProps> = ({ activeLanguage }) => {
    const [qrPopup, setQrPopup] = useState(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const toggleQrPopup = () => {
        setQrPopup(!qrPopup);
    }

    const location = useLocation();
    if (location.pathname.startsWith('/registration_form') || location.pathname.startsWith('/login') || location.pathname.startsWith('/admin') || location.pathname.startsWith('/drivers-form')) {
        return null;
    }

    return (
        <div className='m-auto max-w-1128  px-4 1180:px-0 select-none'>
            <div className='grid sm:grid-cols-2 xmd:grid-cols-4'>
                <div className='flex flex-col items-start basis-[25%] mb-7 md:mb-0'>
                    <LogoSvg fill="#002fff" className="h-10 main-color-logo " />
                </div>
                <div className='flex flex-col items-start'>
                    <div className='text-md text-semibold mb-6 text-main'>
                        {languageData.menu.passenger}
                    </div>
                    <div className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100' onClick={toggleQrPopup}>
                        {languageData.menu.gta}
                    </div>
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/passenger`} className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.cq}
                    </Link>
                    <a href="mailto:support@taxi.gr" className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.support}
                    </a>
                </div>
                <div className='flex flex-col items-start  mt-4 xmd:mt-0'>
                    <div className='text-md text-semibold  mb-4  xmd:mb-6 text-main'>
                        {languageData.menu.driver}
                    </div>
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/driver`} className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.dwt}
                    </Link>
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/driver-faq`} className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.cq}
                    </Link>
                    <a href="mailto:driver@taxi.gr" className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.support}
                    </a>
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/drivers-form`} className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.su}
                    </Link>
                </div>
                <div className='flex flex-col items-start mt-4 xmd:mt-0'>
                    <div className='text-md text-semibold mb-4  xmd:mb-6 text-main'>
                        {languageData.menu.company}
                    </div>
                    {/* <div className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.about}
                    </div> */}
                    {/* <Link to={`${activeLanguage === 'en' ? '/en' : ''}/press`} className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.press}
                    </Link> */}
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/news`} className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.news}
                    </Link>
                    {/* <div className='text-semibold mb-3 text-neutral-600 cursor-pointer hover:text-main duration-100'>
                        {languageData.menu.careers}
                    </div> */}
                </div>
            </div>
            <div className='flex justify-center xmd:justify-between mt-4 items-center pt-4 xmd:py-4 xmd:border-b border-neutral-200'>
                <div className=''>
                    <div className="flex text-main gap-3 items-center justify-center">
                        <a href="https://www.instagram.com/taxi.gr/" target='_blank' className='h-full flex items-center'>
                            <InstagramIcon className="h-4 xmd:text-base cursor-pointer" />
                        </a>
                        <a href="https://www.tiktok.com/@taxi.gr" target='_blank' className='h-full flex items-center'>
                            <TiktokIcon className="h-4 xmd:text-base cursor-pointer" />
                        </a>
                        <a href="https://www.facebook.com/taxi.grOfficial/" target='_blank' className='h-full flex items-center'>
                            <FacebookIcon fill="#002fff" strokeWidth={0} className="h-4 xmd:text-base cursor-pointer" />
                        </a>
                        <a href="https://twitter.com/taxi_gr" target='_blank' className='h-full flex items-center'>
                            <TwitterIcon fill="#002fff" strokeWidth={0} className="h-4 text-lg xmd:text-base cursor-pointer" />
                        </a>
                        <a href="https://gr.linkedin.com/company/taxigr" target='_blank' className='h-full flex items-center'>
                            <LinkedinIcon fill="#002fff" strokeWidth={0} className="h-4 xmd:text-base cursor-pointer" />
                        </a>
                    </div>
                </div>
                <div className='xmd:block hidden'>
                    <ActionButton activeLanguage={activeLanguage} className={"inline-block text-sm btn btn-primary font-bold bg-main py-2 px-4 rounded-full text-white hover:bg-secondary duration-100"} text_english={'Get the app'} text_greek={'Κατέβασε την εφαρμογή'} onClick={toggleQrPopup} />
                </div>
            </div>
            {qrPopup && <QrPopup onClose={() => setQrPopup(false)} activeLanguage={activeLanguage} header_text={languageData.home.home_popup_header} body_text={languageData.home.home_popup_text} />}
            <div className='my-4 flex xmd:flex-row flex-col-reverse gap-3 xmd:justify-between items-center'>
                <div className='text-neutral-500 text-sm'>
                    &#169; {new Date().getFullYear()} taxi.gr
                </div>

                <div className="flex text-xs gap-3">
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/terms-and-conditions`} className="hover:text-neutral-500 text-neutral-400 cursor-pointer">
                        Terms & Conditions
                    </Link>
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/privacy-policy`} className="hover:text-neutral-500 text-neutral-400 cursor-pointer">
                        Privacy
                    </Link>
                    <Link to={`${activeLanguage === 'en' ? '/en' : ''}/terms-and-conditions`} className="hover:text-neutral-500 text-neutral-400 cursor-pointer">
                        Cookies
                    </Link>
                </div>

            </div>
        </div>

    )
}

export default Footer;