import React from 'react';
import headerImage from '../../../images/press/press_header.jpg'
import DefaultLanguageInterface from '../../../interfaces';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import HTMLRenderer from '../../../utils/HTMLRenderer';
import SectionWrapper from '../../../utils/SectionWrapper/SectionWrapper';


const PressHeader: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.4)), url(${headerImage})`,
        backgroundSize: 'cover',
        height: '54vh',
    };

    return (
        <SectionWrapper>
            <div style={backgroundImageStyle}
                className='max-w-1128 mx-auto mt-6 px-6 pb-4 xmd:px-10 rounded-2xl select-none relative overflow-hidden bg-[position:28%_100%] sm:bg-[position:20%_30%] md:bg-[position:40%_30%] '>
                <div className="max-w-1128 animate-fadeUp w-full h-full flex flex-col justify-end text-white m-auto">
                    <div className='delay-75 animate-fadeUp mb-4 xmd:mb-6 font-thin text-lg leading-8 w-full xmd:min-w-[400px] xmd:w-[32vw]' />
                    <div className='button_div delay-150 animate-fadeUp scale-0.75 mb-[2vh] xmd:mb-[6vh]'>
                        <HTMLRenderer htmlContent={languageData.press.header_subtitle} className='text-lg' />
                    </div>
                </div>
            </div>
        </SectionWrapper>
    )
}
export default PressHeader;