import React from "react";
import TaxiDriver1 from '../../../../images/downloadTheApp/taxi_driv_1.png'
import TaxiDriver2 from '../../../../images/downloadTheApp/taxi_driv_2.png'

interface StackedImagesProps {
    mode: string;
    className?: string;
}

const StackedImages: React.FC<StackedImagesProps> = ({ mode, className }) => {
    return (
        <>
            {mode === 'passenger' ?
                <></>
                :
                <div className={`max-w-[65vw] relative h-full w-full min-h-[320px] mb-[-20px] mr-[20px] ${className}`}>
                    <img src={TaxiDriver1} alt="" className="absolute h-3/4 left-[18%] top-[15%]" />
                    <img src={TaxiDriver2} alt="" className="absolute h-3/4 left-[42%] top-[10%]" />
                </div>
            }
        </>
    )
}

export default StackedImages