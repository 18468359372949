import React from 'react';
import LogoSvg from '../../../../images/LogoSvg';
import DefaultLanguageInterface from '../../../../interfaces';
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import headerBg from '../../../../images/driver/driver_cut.jpg';

const DriverHeaderImg: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    return (
        <div
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${headerBg})` }}
            className="w-full h-[64dvh]  lg:h-[78vh] bg-cover bg-top bg-no-repeat   pb-10"
        >
            <div className='h-full w-full flex flex-col justify-end sm:justify-center max-w-1128 m-auto sm:pt-[22vh]    '>
                <div className="w-full  lg:w-1/2 px-4 flex flex-col justify-center text-white">
                    <div className="text-[2.2rem]  lg:text-6xl font-semibold leading-tight max-w-[520px] animate-fadeUp">
                        <span >{languageData.driver.header_title}</span>
                        {/* <LogoSvg
                            fill={'#fff'}
                            pathStyle="duration-200 transition-property-fill"
                            className="h-[2.15rem] sm:h-[3rem]  lg:h-[3.5rem] mt-1.5 sm:mt-4 ml-1"
                        /> */}
                    </div>
                    <div className="mt-2 text-lg  lg:text-xl font-thin leading-6 max-w-[400px] sm:max-w-[450px] animate-fadeUp delay-75">
                        {languageData.driver.header_subtitle}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default DriverHeaderImg;
