import React from 'react';
import { Link } from 'react-router-dom';
import './HomeDriver.css';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import LogoSvg from '../../../images/LogoSvg';


interface HomeDriverProps {
    activeLanguage: string;
    buttonText? : string;
    buttonClickLink?: string;
}

const HomeDriver: React.FC<HomeDriverProps> = ({ activeLanguage, buttonText, buttonClickLink }) => {

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    return (
        <div className='bg-neutral-50'>
            <div className='max-w-1128 flex px-4 1180:px-0 flex-col mx-auto pt-14 xmd:pt-16 pb-14'>
                <h3 className='text-4xl xmd:text-5xl mb-4 xmd:mb-14 xmd:text-center text-neutral-800 font-semibold flex flex-col justify-start items-start sm:flex-row sm:items-center sm:justify-center'>
                    <span>
                        {languageData.home.home_driver_title}
                    </span> 
                    <LogoSvg fill={'#002fff'} pathStyle="duration-200 transition-property-fill" className="h-[2.8rem] sm:h-[3rem] mt-2.5 sm:mt-4 ml-1 sm:ml-5" />

                </h3>
                <div className='grid grid-cols-1  xmd:grid-cols-2 gap-2 py-18 bg-gray-50 '>
                    <div className="text-2xl mb-4 text-neutral-800 font-semibold xmd:hidden">
                        {languageData.home.home_driver_text}
                    </div>
                    <div className='flex items-center justify-center xmd:justify-end driver-image-div rounded-md h-[480px]'>
                    </div>

                    <div className='flex items-start justify-center xmd:pl-14 flex-col'>
                        <div className="text-[2.2em] leading-[2.5rem] text-neutral-700 font-semibold hidden xmd:block pb-6">
                            {languageData.home.home_driver_text}
                        </div>
                        {/* <div className="py-2 text-lg text-neutral-700">
                            {languageData.home.home_driver_text}
                        </div> */}
                        <div className='my-2'>
                            <div className='flex gap-4'>
                                <div className='ion-icon-default-transform bg-main text-sm h-6 w-6 items-center flex justify-center rounded-full text-white'>
                                    1
                                </div>
                                <h4 className='text-neutral-900 text-lg pb-1'>
                                    {languageData.home.home_driver_point_3_title}
                                </h4>
                            </div>
                            <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                                {languageData.home.home_driver_point_3_text}
                            </div>
                        </div>

                        <div className='my-2'>
                            <div className='flex gap-4'>
                                <div className='ion-icon-default-transform bg-main text-sm min-h-[24px] min-w-[24   px] h-6 w-6 items-center flex justify-center rounded-full text-white'>
                                    2
                                </div>
                                <h4 className='text-neutral-900 text-lg pb-1'>
                                    {languageData.home.home_driver_point_4_title}
                                </h4>
                            </div>
                            <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                                
                                {languageData.home.home_driver_point_4_text}
                            </div>
                        </div>

                        <div className='my-2'>
                            <div className='flex gap-4'>
                                <div className='ion-icon-default-transform bg-main text-sm h-6 w-6 items-center flex justify-center rounded-full text-white'>
                                    3
                                </div>
                                <h4 className='text-neutral-900 text-lg pb-1'>
                                    {languageData.home.home_driver_point_2_title}
                                </h4>
                            </div>
                            <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                                {languageData.home.home_driver_point_2_text}
                            </div>
                        </div>


                        <Link to={buttonClickLink ? buttonClickLink : `${activeLanguage == 'en' ? '/en' : ''}/driver`}  className='mt-4 text-base py-2 flex gap-2 px-5 justify-center bg-neutral-200 w-full xmd:w-auto text-neutral-900 rounded-full hover:bg-neutral-300 duration-200'>
                            {buttonText ? buttonText : languageData.home.home_driver_learn_more}
                        </Link>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default HomeDriver;