import React from "react";
import DefaultLanguageInterface from "../../../../interfaces";
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../../@/components/ui/accordion";
import HTMLRenderer from "../../../../utils/HTMLRenderer";

const DefaultDriverAccordeon: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    return (
        <Accordion type="single" collapsible className="w-full main_accordion">
            <AccordionItem value="popular-questions">
                <AccordionTrigger>{languageData.driver_faq.q1}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q1_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q1_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q1_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q1_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q1_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q1_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q1_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q1_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q1_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q1_5a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="mobile-app">
                <AccordionTrigger>{languageData.driver_faq.q2}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_7a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-8">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_8}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_8a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-9">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q2_9}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q2_9a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="your-trip">
                <AccordionTrigger>{languageData.driver_faq.q3}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q3_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q3_1a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="comments_complaints">
                <AccordionTrigger>{languageData.driver_faq.q4}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_7a} />
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem value="mobile-app__inner-8">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_8}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_8a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-9">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_9}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_9a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-10">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_10}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_10a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-11">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q4_11}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q4_11a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="drivers_portal">
                <AccordionTrigger>{languageData.driver_faq.q5}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q5_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q5_7a} />
                            </AccordionContent>
                        </AccordionItem>

                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="cri">
                <AccordionTrigger>{languageData.driver_faq.q6}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_7a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-8">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_8}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_8a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-9">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_9}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_9a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-10">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_10}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_10a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-11">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_11}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_11a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-12">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_12}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_12a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-13">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_13}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_13a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-14">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_14}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_14a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-15">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_15}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_15a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-16">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_16}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_16a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-17">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_17}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_17a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-18">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_18}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_18a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-19">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_19}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_19a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-20">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q6_20}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q6_20a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="oi">
                <AccordionTrigger>{languageData.driver_faq.q7}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q7_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q7_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q7_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q7_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="driver_faq_sub_title">{languageData.driver_faq.q7_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.driver_faq.q7_3a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    )
}

export default DefaultDriverAccordeon;