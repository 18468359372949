import React, { useState, useEffect } from 'react';

import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import AdminBox from '../Helpers/AdminBox/AdminBox';
import { useAuthContext } from '../../../utils/useAuthContext';
import AdimBoxTitle from '../Helpers/AdminBoxTitle/AdimBoxTitle';
import { ACTIVE_URL } from '../../../constants';
import HTMLRenderer from '../../../utils/HTMLRenderer';

interface Log {
    id: number;
    log_date: string;
    title: string;
    description: string;
}

const AdminLogs = () => {
    const { authTokens } = useAuthContext();
    const [logs, setLogs] = useState<any>([]);

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_dev_logs/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();

                    setLogs(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchResponses();
    }, []);

    return (
        <div className=''>
            <AdminMainHeader pageTitle='Developer Logs' />
            {logs.map((log: Log) => (
                <div key={log.title + '_' + log.id}>
                    <AdminBox>
                        <AdimBoxTitle title={log['title']} secondary_title={log['log_date'].replace(/-/g, '/')} />
                        <div className='px-4 py-2'>
                            <HTMLRenderer htmlContent={log['description']} />
                        </div>
                    </AdminBox>
                </div>
            ))}
        </div>
    )
};

export default AdminLogs;