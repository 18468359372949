import React, { useState, useEffect } from 'react';
import './CandidateRows.css';
import { IonIcon } from '@ionic/react';
import AdminViewCandidate from '../../AdminViewCandidate/AdminViewCandidate';

interface Candidate {
    id: number;
    name: string;
    job_fit_score: number;
    stage: string;
    date_created: string;
    isLast?: boolean;
    selected?: boolean;
    changeSelected: (id: number) => void;
}

const CandidtatesTableRow: React.FC<Candidate> = ({ id, name, job_fit_score, changeSelected, stage, date_created, isLast, selected }) => {
    const initialSelected = selected !== undefined ? selected : false;
    const [isSelected, setIsSelected] = useState<boolean>(initialSelected);
    const [showCandidate, setShowCandidate] = useState<boolean>(false);

    const isEven = id % 2 === 0;

    const toggleSelected = () => {
        setIsSelected(!isSelected);
        changeSelected(id);
    }

    useEffect(() => {
        setIsSelected(selected !== undefined ? selected : false);
    }, [selected]);

    const handleShowCandidate = () => {
        setShowCandidate(!showCandidate);
    }

    return (
        <div>
            {
                showCandidate ? (
                    <AdminViewCandidate id={id} onClose={handleShowCandidate} show={showCandidate} />
                ) : null
            }
            <div onClick={handleShowCandidate} className={`candidates_table_row border-gray-400 border-t ${isEven ? '' : 'bg-lightGray'} ${isLast ? 'rounded-b-sm' : ''} hover:bg-hoverBlue cursor-pointer`}>

                <div className='h-10 flex items-center px-2'>
                    <label className={`inline-flex items-center cursor-pointer p-2 h-9`}>
                        <input
                            type="radio"
                            className="hidden"
                        />
                        {isSelected === true ? (
                            <span onClick={toggleSelected} className="relative flex items-center justify-center w-4 h-4 bg-blue-500 border-blue-500 rounded cursor-pointer">
                                <IonIcon icon="checkmark-outline" className="absolute text-white font-semibold" />
                            </span>
                        ) : (
                            <span onClick={toggleSelected} className="relative flex items-center justify-center w-4 h-4 border rounded border-gray-600 cursor-pointer">
                            </span>
                        )}
                    </label>
                    {name}
                </div>
                <div className='h-10 flex items-center px-2 border-gray-400 border-l'>
                    {stage}
                </div>
                <div className='h-10 flex items-center px-2 border-gray-400 border-l'>
                    {job_fit_score ? job_fit_score + '%' : '-'}
                </div>
                <div className='h-10 flex items-center px-2 border-gray-400 border-l'>
                    {date_created}
                </div>

            </div>
        </div>

    )
}

export default CandidtatesTableRow;