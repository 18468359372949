import React from 'react';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';

const AdminHome: React.FC = () => {
    return (
        <div className='admin-home'>
            <div className='page-header'>
                <AdminMainHeader pageTitle='Dashboard' />
            </div>

        </div>
    )
};

export default AdminHome;