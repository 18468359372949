import React from "react";

interface ActionButtonProps {
    activeLanguage: string;
    text_greek: string;
    text_english: string;
    onClick: () => void;
    className?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text_greek, text_english, onClick, activeLanguage, className = "btn btn-primary bg-main py-[0.565rem] px-6 rounded-full hover:bg-blue-700 duration-100" }) => {

    return (
        <button
            className={className}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            {activeLanguage === 'el' ? text_greek : text_english}
        </button>
    )

}

export default ActionButton;