import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import './UndecitedThumbs.css';

interface UndecitedThumbsProps {
    className?: string;
}

const UndecitedThumbs: React.FC<UndecitedThumbsProps> = ({ className }) => {
    return (
        <div className={`text-xs thumbs ${className}`}>
            <ThumbsUpIcon className="like-thumb"/>
            <ThumbsDownIcon className="dislike-thumb"/>
        </div>
    )
}

export default UndecitedThumbs;