import { SectionWrapperProps } from "../../interfaces";



const SectionWrapper:React.FC<SectionWrapperProps> = ({children, className}) => {

    return (
        <div className={`max-w-1128 m-auto px-4 1180:px-0 py-8 sm:py-16   ${className ? className : ''}`}>
            {children}
        </div>
    )
}

export default SectionWrapper;