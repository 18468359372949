// components/LanguageLayout.tsx
import React, { ReactNode } from 'react';
import { Language } from '../../interfaces';
import {useLanguageInitializer} from './LanguageInitialization';

interface LanguageLayoutProps {
  children: ReactNode;
  setActiveLanguage: (lang: Language) => void;
}

const LanguageLayout: React.FC<LanguageLayoutProps> = ({ children, setActiveLanguage }) => {
  useLanguageInitializer(setActiveLanguage);

  return <>{children}</>;
};

export default LanguageLayout;
