import React from "react";
import DefaultLanguageInterface from "../../../interfaces";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import WhyUs from "./WhyUs/WhyUs";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import SectionWrapper from "../../../utils/SectionWrapper/SectionWrapper";

const DriverReasons: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    return (
        <SectionWrapper className="mt-10 lg:mt-0">
                <SectionTitle title={languageData.driver.reasons_title} className="!text-start lg:max-w-[500px] lg:text-5xl lg:mb-10"/>
                <WhyUs activeLanguage={activeLanguage} />

        </SectionWrapper>
    )
}

export default DriverReasons;