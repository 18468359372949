import React, { useEffect, useState } from 'react';
import { Input } from "../../../@/components/ui/input";

interface DriverFormEmailProps {
    sendEmail: (email: string) => void;
    preSelectedEmail?: string;
}

const DriverFormEmail: React.FC<DriverFormEmailProps> = ({ sendEmail, preSelectedEmail = '' }) => {
    const [email, setEmail] = useState<string>(preSelectedEmail);
    const [emailFieldTouched, setEmailFieldTouched] = useState(false);

    useEffect(() => {
        // This effect will update the email state whenever the preSelectedEmail prop changes
        setEmail(preSelectedEmail);
    }, [preSelectedEmail]); // Watching for changes in preSelectedEmail

    // Utility function to validate email
    const isValidEmail = (email: string) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEmail = event.target.value;
        setEmail(updatedEmail);
        sendEmail(isValidEmail(updatedEmail) ? updatedEmail : '');
    };

    return (
        <div>
            <Input
                type="email"
                id="email"
                placeholder="Enter your email"
                className={`w-full text-[0.95rem] h-12 pl-2 pr-2 text-neutral-900 rounded-md outline-none bg-neutral-100 border ${isValidEmail(email) && emailFieldTouched ? 'border-green-400' : (!emailFieldTouched ? 'border-neutral-200' : 'border-red-500')} focus:pl-[6.68px] focus:border-2 focus:border-main focus:shadow-md`}
                value={email}
                onBlur={() => setEmailFieldTouched(true)}
                onChange={handleEmailChange}
            />
            {!isValidEmail(email) && emailFieldTouched && (
                <div className="text-xs text-red-500 mt-1 ml-1">
                    Please enter a valid email address.
                </div>
            )}
        </div>
    );
};

export default DriverFormEmail;