import React from 'react';
import { IonIcon } from '@ionic/react';

interface TagItemProps {
    tag: string;
    tagId: string;
    onDelete: (tagId: string) => void;
}

const TagItem: React.FC<TagItemProps> = ({ tag, tagId, onDelete }) => {

    return (
        <div className='border rounded-sm w-fit px-2 h-8 flex gap-1 items-center justify-center select-none hover:bg-gray-100 hover:cursor-pointer'>
            <div className='text-sm'>
                {tag}
            </div>
            <div className='flex justify-center px-1 cursor-pointer' onClick={() => onDelete(tagId)}>
                <IonIcon icon='close-circle-outline' />
            </div>
        </div>
    )
}

export default TagItem;
