import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Navbar from './Components/Navigation/NavBar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import Press from './Pages/Press/Press';
import Contact from './Pages/Contact/Contact';
import Driver from './Pages/Driver/Driver';
import Admin from './Pages/Admin/Admin';
import LoginPage from './Pages/Login/LoginPage';
import PrivacyPolicyPage from './Pages/Misc/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsAndConditionsPage from './Pages/Misc/TermsAndConditionsPage/TermsAndConditionsPage';
import SingleNewsPage from './Pages/SingleNewsPage/SingleNewsPage';
import PassengerFAQPage from './Pages/FAQs/PassengerFAQ/PassengerFAQPage';
import DriverCommonQuestionsPage from './Pages/DriverCommonQuestions/DriverCommonQuestionsPage';
import NotFound from './Pages/Misc/NotFound/NotFound';
import NewsHome from './Pages/NewsListPage/NewsListPage';
import DriversFormNewPage from './Pages/DriversFormNewPage/DriversFormNewPage';

import { AuthProvider } from './utils/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import LanguageLayout from './utils/LanguageInitialization/LanguageLayoutProps ';
import { RedirectComponent } from './utils/AppRedirectLink/AppRedirectLink';
import { ToastProvider } from "./@/components/ui/toast"
import { LanguageChangerProps } from './interfaces';

import './App.css';

const getPath = (path: string, activeLanguage: string) => {
  return activeLanguage === 'el' ? path : `/:lang${path}`;
};

const AppRoutes: React.FC<LanguageChangerProps> = ({ activeLanguage, setActiveLanguage }) => (
  <Routes>
    <Route path={getPath('/', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><Home activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/news', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><NewsHome activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/driver', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><Driver activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/passenger', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><PassengerFAQPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/driver-faq', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><DriverCommonQuestionsPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/news/:slug', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><SingleNewsPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/press', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><Press activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/contact', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><Contact activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/privacy-policy', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><PrivacyPolicyPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/terms-and-conditions', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><TermsAndConditionsPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/drivers-form', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><DriversFormNewPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/form', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><DriversFormNewPage activeLanguage={activeLanguage} /></LanguageLayout>} />

    <Route path={getPath(`/app/website/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='website' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/in/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='instagram' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/tt/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='tiktok' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/fb/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='facebook' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/ga/s/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='google_ads_passenger_search' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/ga/d/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='google_ads_display_passenger' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/ga/g/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='google_ads_demang_gen_passenger' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/ga/p/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='google_ads_pmax_passenger' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/ga/y/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='google_ads_youtube_passenger' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/oa/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='otc_ads' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/od/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='outdoor' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/a5/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='a5' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/cub/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='cub' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/tx/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='cars' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/ex/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='exhibitions' />
      </LanguageLayout>
    } />

    <Route path={getPath(`/app/air/:user`, activeLanguage)} element={
      <LanguageLayout setActiveLanguage={setActiveLanguage}>
        <RedirectComponent activeLanguage={activeLanguage} from='airport' />
      </LanguageLayout>
    } />

    <Route path="*" element={<LanguageLayout setActiveLanguage={setActiveLanguage}><NotFound activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/admin', activeLanguage)}
      element={
        <PrivateRoute authenticationPath={getPath('/login', activeLanguage)} >
          <Admin />
        </PrivateRoute>
      }
    />

    <Route path={getPath('/login', activeLanguage)} element={<LoginPage />} />
  </Routes>
);

const App: React.FC = () => {
  const [activeLanguage, setActiveLanguage] = React.useState('el'); // ['en', 'el']
  const [, setInWhiteBackground] = React.useState(false);

  const handleLanguageChange = (language: string) => {
    setActiveLanguage(language);

  };

  React.useEffect(() => {
    const handleLocationChange = () => {
      const pathname = window.location.pathname;
      const isNotFound = !['/', '/registration_form', '/driver', '/passenger', '/driver-faq', '/news', '/press', '/contact', '/privacy-policy', '/terms-and-conditions', '/admin', '/login'].includes(pathname);
      setInWhiteBackground(isNotFound);
    };

    handleLocationChange(); // Initial check

    window.addEventListener('popstate', handleLocationChange); // Listen for back/forward navigation

    return () => {
      window.removeEventListener('popstate', handleLocationChange); // Cleanup listener
    };
  }, []);

  const queryClient = new QueryClient();
  
  return (
    <BrowserRouter>
    <ToastProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Navbar activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
          <AppRoutes activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
          <Footer activeLanguage={activeLanguage} />
        </QueryClientProvider>
      </AuthProvider>
      </ToastProvider>
    </BrowserRouter>

  );
};
export default App;
