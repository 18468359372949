import React, { useState } from 'react';
import { LucideIcon } from 'lucide-react';

interface WhyUsSquareProps {
    className?: string;
    title: string;
    content_text: string;
    Icon: LucideIcon;
    border?: boolean;
}

const WhyUsSquare: React.FC<WhyUsSquareProps> = ({ title, content_text, Icon, border = true }) => {

    return (
        <div className={` lg:h-40 cursor-pointer lg:hover:scale-105 border-b pb-3 lg:pb-0 border-b-neutral-150 transition-all ease-in-out  overflow-hidden relative lg:border lg:shadow-md lg:rounded-md lg:border-neutral-50 lg:bg-neutral-50 `}
        >
            <div className="p-0.5  lg:p-3.5 h-full">
                <h3 className={`text-xl transition-colors duration-200 flex flex-col-reverse  lg:flex-row justify-between gap-2 lg:mb-2`}>
                    <div className='w-[85%]  lg:w-[90%] text-[1.05rem]  lg:text-xl text-neutral-800 mt-[-8px] sm:mt-0'>{title}</div>
                    <div>
                        <Icon height={29} width={29} className="text-3xl text-main" />
                    </div>
                </h3>
                <p className="text-neutral-500 w-[95%] text-[0.9rem] leading-5">{content_text}</p>
            </div>
        </div>
    );
}

export default WhyUsSquare;
