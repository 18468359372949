import React, { useEffect, useState } from 'react';
import { useQuery } from "@tanstack/react-query";

import { fetchPrivacyPolicy } from '../../../../queries';
import { DefaultLanguageInterface, PrivacyPolicyDescription } from '../../../../interfaces';
import HTMLRenderer from '../../../../utils/HTMLRenderer';

const PrivacyPolicyContent:React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [privacyPolicyContentGr, setPrivacyPolicyContentGr] = useState<string>('');
    const [privacyPolicyContentEn, setPrivacyPolicyContentEn] = useState<string>('');
    const { data: privacyPolicyContent, error } = useQuery<PrivacyPolicyDescription, Error>({
        queryKey: ["privacyPolicy"],
        queryFn: fetchPrivacyPolicy,
        staleTime: 5 * 60 * 1000
    });

    if (error) {
        console.error('Error fetching privacy policy:', error);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (privacyPolicyContent) {
            setPrivacyPolicyContentGr(privacyPolicyContent.description_gr);
            setPrivacyPolicyContentEn(privacyPolicyContent.description_en);
        }
    }, [privacyPolicyContent]);


    return (
        <div className='max-w-1128 m-auto pt-12 pb-10 px-4  1180:px-0 xmd:pt-20 xmd:pb-36 '>
            <HTMLRenderer htmlContent={activeLanguage === 'el' ? privacyPolicyContentGr : privacyPolicyContentEn} />
        </div>
    )
}

export default PrivacyPolicyContent;