import React from 'react';
import './ContactInfo.css';
import ContactLocationButton from '../../Buttons/ContactLocationButton/ContactLocationButton';

const ContactInfo: React.FC = () => {

    return (
        <div className='contact_info-wrapper bg-gray-50'>
            <div className='contact_info'>
                <div className='contact_info-title'>
                    Στοιχεία επικοινωνίας
                </div>
                <div className='contact_info-main'>
                    <div className='contact_info-main-mails'>
                        <div className='contact_info-main-mails-title'>
                            Ηλεκτρονικό ταχυδρομείο
                        </div>
                        <ul>
                            <li>
                                Για <b>οποιοδήποτε ζήτημα υποστήρηξης</b> όπως αναγράφονται στις <a href='/passenger#passenger_faq-container' className='underline'>Συχνές Ερωτήσεις</a>: <a href='mailto:support@taxi.gr'>support@taxi.gr</a>
                            </li>
                            <li>
                                Για <b>υποστήρηξη για οδηγούς</b>: <a href='mailto:driver@taxi.gr'>driver@taxi.gr</a>
                            </li>
                            <li>
                                Για <b>οιπαδήποτε άλλο ζήτημα</b>: <a href='mailto:info@taxi.gr'>info@taxi.gr</a>
                            </li>
                        </ul>

                    </div>
                    <div className='contact_info-main-locations'>
                        <div className='contact_info-main-locations-title'>
                            Διευθύνσεις
                        </div>
                        <ul>
                            <li>
                                <div className='loc_div'>
                                    Γραφείο Οδηγών: Μάρνη 17, 10443, Αθήνα
                                </div>
                                <ContactLocationButton url={'https://goo.gl/maps/f4EKj78ozuBwFM5QA'} />
                            </li>
                            <li>
                                <div className='loc_div'>
                                    Κεντρικά γραφεία: Λεωφόρος Αθηνών 142, 10442, Αθήνα (<b>συναντήσεις κατόπιν ραντεβού</b>)
                                </div>

                                <ContactLocationButton url={'https://goo.gl/maps/isATnMBbQbnHg7Ut6'} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ContactInfo;