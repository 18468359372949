import React, { useState, useEffect } from 'react';
import { ACTIVE_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';
import Modal from '../../../../utils/Modal/Modal';
import { IonIcon } from '@ionic/react';
import AdminViewCandidateLeftPanel from './AdminViewCandidateLeftPanel/AdminViewCandidateLeftPanel';
import AdminViewCandidateRightPanel from './AdminViewCandidateRightPanel/AdminViewCandidateRightPanel';
import './AdminViewCandidate.css';

interface Note {
  id: number;
  note: string;
  days_since_date_created: number;
  info_author: string;
}

interface candidateInfo {
  id: number;
  name: string;
  email: string;
  phone: string;
  score: number;
  stage: string;
  date_created: string;
  notes: Note[];
}

interface AdminViewCandidateProps {
  id: number;
  onClose: () => void;
  show: boolean;
}

const AdminViewCandidate: React.FC<AdminViewCandidateProps> = ({ id, onClose, show }) => {
  const { authTokens } = useAuthContext();
  const [candidateInfo, setCandidateInfo] = useState<candidateInfo | null>(null);

  useEffect(() => {
    const fetchResponses = async () => {
      if (!authTokens || !authTokens.access) {
        return;
      }
      try {
        const response = await fetch(`${ACTIVE_URL}/api/get_candidate/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`,
          }
        });
        if (response.ok) {
          const data = await response.json();
          setCandidateInfo(data);
        } else {
          console.error('Failed to fetch responses');
        }
      } catch (error) {
        console.error('Error fetching responses:', error);
      }
    };
    fetchResponses();
  }, [id, authTokens]);

  return (
    <div className=''>
      <Modal show={show} onClose={onClose} titleText='Edit Candidate' width='w-3/4' titleIcon={<IonIcon icon="person" className='text-md ion-icon-default-transform mr-1' />}>
        {candidateInfo ? (
          <div className='candidate-edit-panel'>
            <AdminViewCandidateLeftPanel thisCandidateInfo={candidateInfo} />
            <AdminViewCandidateRightPanel thisCandidateInfo={candidateInfo} />
          </div>
        ) : (
          <div className='flex w-full items-center py-12 justify-center'>Loading candidate information...</div> 
        )}
      </Modal>
    </div>
  )
}

export default AdminViewCandidate;
