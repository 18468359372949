import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Sling as Hamburger } from 'hamburger-react';
import { RemoveScroll } from 'react-remove-scroll';
import { InstagramIcon, FacebookIcon, TwitterIcon, LinkedinIcon } from 'lucide-react';

import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

import { ReactComponent as AccesibilityIcon } from '../../images/navbar/accessibility.svg';
import { ReactComponent as CarSportIcon } from '../../images/navbar/car_sport.svg';
import { ReactComponent as ClipboardIcon } from '../../images/navbar/clipboard.svg';
import { ReactComponent as MailIcon } from '../../images/navbar/mail.svg';
import { ReactComponent as TiktokIcon } from '../../images/footer/logo_tiktok.svg'
import LogoSvg from '../../images/LogoSvg';
import grLoc from '../../images/Taxi_icons/svgs/gr_loc.svg';
import enLoc from '../../images/Taxi_icons/svgs/en_loc.svg';

import MenuSidebarItem from "./MenuSidebarItem/MenuSidebarItem";


interface NavbarProps {
    activeLanguage: string;
    setActiveLanguage: (language: string) => void;
}

const Navbar: React.FC<NavbarProps> = (props) => {
    const { activeLanguage, setActiveLanguage } = props;
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuClosing, setMenuClosing] = useState(false);
    const [inWhiteBackground, setInWhiteBackground] = useState(false);
    const mainMenuRef = useRef<HTMLDivElement | null>(null);

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [isFixed, setIsFixed] = useState(false);
    const [isOnTop, setIsOnTop] = useState(true);

    const handleMenuToggle = (clicked_outside?: boolean) => {
        if (isMenuOpen || clicked_outside) {
            setMenuClosing(true);
            setTimeout(() => {
                setMenuClosing(false);
                setMenuOpen(false);
            }, 501);
        } else {
            setMenuOpen(!isMenuOpen);
        }
    };

    function useOutsideMainMenuAlerter(mainMenuRef: any) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (mainMenuRef !== null && mainMenuRef.current !== null) {
                    if (mainMenuRef.current && !mainMenuRef.current.contains(event.target)) {
                        handleMenuToggle(true);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [mainMenuRef]);
    }
    useOutsideMainMenuAlerter(mainMenuRef);


    const setLanguage = (language: string) => {

        setActiveLanguage(language);

        const currentPath = window.location.pathname;
        // Remove any existing language prefix
        const pathWithoutLang = currentPath.replace(/^\/(en|el)/, '') || '/';
        // Conditionally prefix the path with 'en' for English, leave as is for Greek ('el')
        const newPath = language === 'en' ? `/en${pathWithoutLang}` : pathWithoutLang;

        navigate(newPath);

    };

    const location = useLocation();
    const pathWithoutLang = activeLanguage === 'en'
        ? location.pathname.replace(`/en`, '')
        : location.pathname;

    const wBMainCondition = ![
        '/',
        '/registration_form',
        '/driver',
        '/passenger',
        '/driver-faq',
        '/contact',
        '/privacy-policy',
        '/terms-and-conditions',
        '/admin',
        '/login'
    ].includes(pathWithoutLang);

    if (wBMainCondition && !inWhiteBackground) {
        setInWhiteBackground(true);
    } else if (!wBMainCondition && inWhiteBackground) {
        setInWhiteBackground(false);
    }



    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY === 0) {
                setIsFixed(false);
                setIsOnTop(true);
            } else if (lastScrollY > currentScrollY) {
                setIsFixed(true);
                setIsOnTop(false);
            } else if (lastScrollY < currentScrollY && isFixed) {
                setIsFixed(false);
                setIsOnTop(false);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);


    if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/registration_form') || location.pathname.startsWith('/drivers-form')) {
        return null;
    }

    const toggleLanguage = () => {
        setLanguage(activeLanguage === 'el' ? 'en' : 'el');
    }

    return (
        <div className={`${isFixed ? 'fixed bg-white shadow-lg  animate-nav-slide-in' : (isOnTop ? 'absolute' : 'fixed top-[-100%] bg-white animate-nav-slide-out shadow-lg')} w-full justify-start z-20 ${isMenuOpen ? 'bg-neutral-50 w-full duration-200' : ''}`}>
            <div className={`max-w-1128 w-full flex justify-between m-auto h-15`}>
                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} className="flex items-center pl-4 1240:pl-0">
                    <LogoSvg fill={`${(isMenuOpen || inWhiteBackground || isFixed) ? '#002fff' : '#fff'}`} pathStyle="duration-200 transition-property-fill" className="h-[2rem]  1128:h-[2rem]" />
                </Link>
                <div className="flex items-center text-white text-header select-none">
                    <div className="relative flex items-center">
                        <div className="flex items-center gap-1 scale-105 xmd:scale-none cursor-pointer" onClick={() => toggleLanguage()}>
                            <img src={`${activeLanguage == 'el' ? grLoc : enLoc}`} alt={`${activeLanguage == 'el' ? 'greek flag' : 'english flag'}`} className="h-[15px] 1128:h-[17px]" />
                            <div className={` ${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-800' : 'text-white'} duration-200  hidden 1128:block`}>{languageData.menu.menu_language}</div>
                            <div className={` ${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-800' : 'text-white'} duration-200 block 1128:hidden text-base`}>{languageData.language.language_short}</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/passenger`} className={`${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-800' : 'text-white'} duration-200 cursor-pointer ml-6 mr-8 hidden 1128:block`}>
                        {languageData.menu.menu_support}
                    </Link>
                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/driver`} className={`${isMenuOpen || inWhiteBackground || isFixed ? 'bg-neutral-800 text-white' : 'bg-white text-black'} duration-200 py-1 1128:py-2 px-2.5 1128:px-4 ml-3 text-sm 1128:mr-4 1128:ml-0 rounded-full cursor-pointer block`} >
                        {languageData.menu.driver}
                    </Link>
                    <div className="cursor-pointer ml-0.5 sm:ml-0 scale-[60%] md:scale-50 rounded-full">
                        <Hamburger easing="ease-in" color={`${isMenuOpen || inWhiteBackground || isFixed ? 'rgb(30, 30, 30)' : 'white'}`} label="Show menu" toggled={isMenuOpen} toggle={() => handleMenuToggle(false)} />
                    </div>
                </div>
            </div>
            {isMenuOpen && (
                <RemoveScroll>
                    <div ref={mainMenuRef} className={`max-w-1128 mx-auto overflow-hidden max-h-0 height-without-header 1128:h-auto ease-in-out px-4  ${isMenuClosing ? 'animate-slideOut' : 'animate-slideIn'}`} >
                        <div className="flex flex-col 1128:flex-row pb-8 1128:pb-0 gap-2 select-none height-without-header-and-footer 1128:h-auto">
                            <div className="w-full 1128:w-3/4 1128:h-80 1128:mt-8 mb-0 1128:mb-4 py-4 px-8 bg-gray-150 rounded-md">
                                <div className="flex gap-2 flex-col 1128:flex-row text-main">
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/driver`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-white hover:shadow-sm rounded-md font-normal cursor-pointer">
                                        {languageData.menu.driver}
                                    </Link>
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/passenger`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-white hover:shadow-sm rounded-md font-normal cursor-pointer" >
                                        {languageData.menu.passenger}
                                    </Link>
                                    <div className="py-1 px-2 text-sm hover:bg-white hover:shadow-sm transition-all duration-75 rounded-md font-normal cursor-pointer">
                                        {languageData.menu.about}
                                    </div>
                                    {/* <Link to={`press`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-white hover:shadow-sm rounded-md font-normal cursor-pointer" >
                                        {languageData.menu.press}
                                    </Link> */}
                                    {/* <div className="py-1 px-2 text-sm hover:bg-white hover:shadow-sm transition-all duration-75 rounded-md font-normal cursor-pointer">
                                        {languageData.menu.careers}
                                    </div> */}
                                    <Link to={`news`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-white hover:shadow-sm rounded-md font-normal cursor-pointer" >
                                        {languageData.menu.news}
                                    </Link>
                                </div>

                            </div>
                            <div className="w-full 1128:w-1/4 m-0 1128:mt-8 1128:mb-4 py-4 px-4 bg-gray-150 text-sm text-black rounded-md overflow-scroll 1128:overflow-hidden">
                                <MenuSidebarItem onClick={handleMenuToggle} icon={<AccesibilityIcon className="p-0.5" />} title={languageData.menu.sidebar.pq_title} text={languageData.menu.sidebar.pq_subtitle} path="/passenger" />
                                <MenuSidebarItem onClick={handleMenuToggle} icon={<CarSportIcon className="p-0.5" />} title={languageData.menu.sidebar.dq_title} text={languageData.menu.sidebar.dq_subtitle} path="/driver-faq" />
                                <MenuSidebarItem onClick={handleMenuToggle} icon={<ClipboardIcon className="p-0.5" />} title={languageData.menu.sidebar.dwu_title} text={languageData.menu.sidebar.dwu_subtitle} path="/driver" />
                                <MenuSidebarItem onClick={handleMenuToggle} icon={<MailIcon className="p-0.5" />} title={languageData.menu.sidebar.git_title} text={languageData.menu.sidebar.git_subtitle} path="mailto:support@taxi.gr" />
                            </div>
                        </div>
                        <div
                            className={`${isMenuClosing ? 'animate-fadeOut' : 'animate-fadeIn delay-500'} w-full opacity-0 select-none items-center animate-fadeIn
                                         absolute bottom-2 left-0 justify-self-end mx-auto px-0.5 mt-2 mb-3 1128:mb-14 flex flex-col
                                        1128:flex-row 1128:justify-between 1128:animate-none 1128:relative 1128:opacity-100 gap-2 1128:gap-0`
                            }
                        >
                            <div className="flex text-xs gap-3">
                                <a href="https://www.instagram.com/taxi.gr/" target='_blank' className='h-full flex items-center'>
                                    <InstagramIcon className="h-3.5 text-main cursor-pointer" />
                                </a>
                                <a href="https://www.tiktok.com/@taxi.gr" target='_blank' className='h-full flex items-center'>
                                    <TiktokIcon className="h-3.5 text-main cursor-pointer" />
                                </a>
                                <a href="https://www.facebook.com/taxi.grOfficial/" target='_blank' className='h-full flex items-center'>
                                    <FacebookIcon fill="#002fff" strokeWidth={0} className="h-3.5 cursor-pointer" />
                                </a>
                                <a href="https://twitter.com/taxi_gr" target='_blank' className='h-full flex items-center'>
                                    <TwitterIcon fill="#002fff" strokeWidth={0} className="h-3.5 cursor-pointer" />
                                </a>
                                <a href="https://gr.linkedin.com/company/taxigr" target='_blank' className='h-full flex items-center'>
                                    <LinkedinIcon fill="#002fff" strokeWidth={0} className="h-3.5 cursor-pointer" />
                                </a>
                            </div>
                            <div className="flex text-xs gap-3">
                                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/terms-and-conditions`} onClick={() => handleMenuToggle(false)} className="text-neutral-300 hover:text-neutral-400 cursor-pointer">
                                    {languageData.menu.tc}
                                </Link>
                                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/privacy-policy`} onClick={() => handleMenuToggle(false)} className="text-neutral-300 hover:text-neutral-400 cursor-pointer">
                                    {languageData.menu.privacy}
                                </Link>
                                <div className="text-neutral-300">
                                    &copy; taxi.gr {new Date().getFullYear()}
                                </div>
                            </div>
                        </div>
                    </div>
                </RemoveScroll>
            )}
        </div>
    )
}

export default Navbar;